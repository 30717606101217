.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
}
.navbrand{
  display:none;
}
.bg-c2e7ff{
  background:#c2e7ff;
}
.bg-ddd{
  background-color: #ddd;
}
  .leftIcon{
    margin-left:auto;
  }
.imgBg{
  background-image: url(https://demo.acellemail.com/core/ux/image/login-bg.png?v=122);
  background-repeat: no-repeat;
  background-position: 60%;
  background-size: cover;
}
.darkform-check-input{
  background-color: darkgray;
  border:1px solid aliceblue;
}
.profilePhoto{
width:7rem;
object-fit: contain;
}
.web-dash{
  display: block;
}
.saddlebrown{
  background:saddlebrown;
}
#editor_container{
  height:86vh;
}
.text-bbb{
  color:#bbb;
}
.c-dark-volin{
  color:darkolivegreen;
}
.log{
  font-size:0.7rem;
}.brandBg{
  background-color: #c2e7ff
}
.bg-progress-success{
  background-color: #c2e7ff;
  background-image: linear-gradient(45deg,#c2e7ff 25%,transparent 25%,transparent 50%,#c2e7ff 50%,#c2e7ff 75%,transparent 75%,transparent);
  background-size: 1rem 1rem;
}
.pde-2{
  padding-right: 1rem;
}
.h5-overflow{
  height:21.3rem;
  overflow-y: auto;
}
.overflowY-auto{
  overflow-y: auto;
}
.w-63{
  width:63%;
}
.btn{
  font-size:0.8rem;
}
.activityaction{
  display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 0.8rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.stat{
  margin-right:0.5rem;
  display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-decoration: none;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.mobile-logo{
  display:none;
}
.w-32{
  width:35%;
}
.c-grey{
  color:grey;
}
.c-gainsboro{
  color:gainsboro
}
input[id="upload"]{
  display:none;
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap; 
  overflow: hidden; 
}
.border-bottom-slate-grey{
  border-bottom:4px solid #3B3C36;
}
.dotted{
  border:1px dotted grey;
}
.dotted-buttom{
  border-bottom: 1px dotted grey;
}
.wt-12{
  width:12rem;
}
.autoview,.automationEnv{
  height:100vh;
  overflow-y: auto;
}
.accordion-item{
  background-color: rgba(0, 25, 39, 0);
}
.accordion-item:first-of-type .accordion-button{
  background-color:rgba(0, 25, 39, 0);
  border:#3B3C36;
  color:white;
}
.campains-select{
  width:auto;
}
.dashSideNav{
  height:70vh;
}
.w-fit-content{
  width:fit-content;
}
.rdw-editor-wrapper {
  border: 1px solid gainsboro;
  border-radius: 10px;
  padding: 1rem;
}
.accordion-button:not(.collapsed) {
  box-shadow:none;
  }
.accordion-button {
    width: 7rem!important;
    padding: 1rem 0.5rem!important;
}
.accordion-item{
  border:none;
}
.w-97{
  width:97%;
}
.clickable:hover{
  cursor: pointer;
  filter:brightness(0.8)
}
.grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.q-img{
  width:50%;
}
.qs-img{
  width:45%;
}
.relative{
  position: relative;
}
.search-icon{
  padding: 0.8rem 1rem;
}
.pdx-4{
  padding-right: 1rem!important;
  padding-left: 1rem!important;
}
.lettered-avatar {
    font-size: 1rem!important;
}
.loading{
opacity:0.6;
}
.mar-5{
  margin-right: 2.2rem;
}
.brand{
  color:white;
}
.action-inpt{
  border:1px solid grey;
  padding: 0.5rem
}
.chat-container{
  position:relative;
  height: 21rem;
  padding: 2rem;
}
.text-slategrey{
  color:#3B3C36;
}
.w-89{
  width:89%;
}
.overflow-x{
  overflow-x:auto;
}
.spaceAround{
  padding:0 1.5rem 0 1.5rem;
}

.bar-container{
  position:relative;
  height: 21rem;
  margin-right: 1rem;
}
.fw-light{
  font-weight: 400;
}
.campaign-select{
  width:40%;
}
.sideNav,.bg-Bc{
  background-color: #3B3C36;
}

*{
  font-family: 'Poppins', sans-serif;
}
.fixedHeightcover{
  height:100vh
}
.h-9{
  height:9.5rem
}
.w-15{
  width:14.9rem;
}
.h-100{
  height: 100%;
}
.bg-lightblue{
  background: lightblue;
}
.fs-6{
  font-size: 0.5rem;
}
.thumb{
  width: 100px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.bg-fade{
  background: #F8FDFF;
  box-shadow: 0.75px 1.5px 8px 0.5px #3B3C36;
  border-radius: 8px;
}
.row {
  --bs-gutter-x:0rem;
}
.dashboardvh{
  height:100vh;
  overflow-y: auto;
}
.cadetblue{
  background-color:cadetblue;
}
.bg-smoke{
  background-color: white;
}
.b-grey{
  color: white;
  background-color: grey;
}
.border-drakslate-grey{
  border: 1px solid #3B3C36;
}
.b-gainsboro{
  background-color: gainsboro;
  color:black;
  border: 1px solid gainsboro;
}
.wt-75{
  width:75%;
}
.pt-7{
  padding-top: 5rem;
}
i.mdi.mdi-account {
  pointer-events: none;
}
.pdx-6{
  padding-right: 4rem;
  padding-left: 4rem;
}
.pde-3{
  padding-right: 2rem;
}
.pd-6{
  padding-right: 4rem;
  padding-left: 4rem;
  padding-top: 2rem;
  padding-bottom: 5rem;
}
.authside-icon{
  display: flex;
  margin:7rem auto 1rem auto;
}
.editorContainer{
  width: 100%;
  overflow-x: auto;
  height: 100vh;
  background: gainsboro;
}
.space{
  letter-spacing: 0.5rem;
  font-size: 0.6rem;
}
.cfvAIS .blockbuilder-branding span.brand {
  display: none;
}
.decoration-none{
  text-decoration: none;
  color:white;
}
.pde-5{
  padding-right: 3rem;
}
.chat-flex{
  display: flex;
}
.bg-slate-grey{
  background-color: #3B3C36;
}
.w-overflow{
  width:100%;
  overflow: auto;
}
.find{
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  letter-spacing: 0.03em;
}
.border-bottom{
  border-bottom: 1px solid grey;
}
.pointer{
  cursor: pointer;
}
.wrap{
  flex-wrap:wrap;
}
.no-wrap{
  flex-wrap: nowrap;
}
.navHeader{
  z-index: 1;
  width: 100%;
  top: 0;
  background:#f6f8fc;
}
.fl-r{
  float: right;
}
.mrx-5{
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.wt-50{
  width:50%;
}
.text-break{
  word-break: break-all;
}
.mbm-2{
  margin-bottom: 0;
}
.pd-1,.pdw-1{
  padding: 1rem;
}

.pdx-5{
  padding-right: 3rem;
  padding-left:3rem;
}
.pde-5{
  padding-left:3rem;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #3B3C36;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.hide-toggler,.show-toggler{
  display:none;
}
.App-link {
  color: #61dafb;
}
.whitesmoke{
  background-color: whitesmoke;
}
.container-fluid{
  padding-left: 0;
  padding-right: 0;
}
.border-ghostwhite{
  border: 1px solid ghostwhite;
}

.wt-75{
  width:75%;
}
.bg-lightBlue{
  background:#f6f8fc;
}
.bg-blue{
  color:#3B3C36;
}
.cl-blue{
  color:#3B3C36;
}
.mt-7{
  margin-top: 7rem;
}
.pb-6{
  padding-bottom: 5.5rem;
}
.pew-5{
  padding-right: 3rem;
}
.bg-azur{
  background-color: white;
}
.wrap-m{
  flex-wrap:nowrap;
}
@media only screen and (min-width:728px) and (max-width:1024px){
  .w-230{
    width: 230px;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1020px) {
  .sideiconflex,.createflex{
    flex-wrap: wrap;
  }
}
@media only screen and (max-width:1020px){
  .h-9{
    height:9.5rem
  }
  .w-15{
    width:13.5rem;
  }
}
@media only screen and (max-width: 766px) {
  .bar-container{
    position:relative;
    height: 21rem;
    margin-right: 0rem;
  }
  .w-89{
    width:100%;
  }
  .brand{
    word-break: break-all;
    font-size: 0.8rem;
  }
  .navbrand{
    display:none;
  }

  .pde-2{
    padding-right:0;
  }
  .bg-slate-grey {
    background-color: #3B3C36;
  }
  .pdx-5{
    padding-right: 1rem;
    padding-left:1rem;
  }
  .pde-5{
    padding-right:1rem;
  }
  .web-dash{
    display:none;
  }
  .mobile-logo{
    display:flex;
    align-items:center;
    margin-bottom: 1rem;
  }
  .w-63{
    width:100%;
  }
  .w-32{
    width:100%
  }
  .automationEnv{
    height:40vh;
  }
  .wrap-m{
    flex-wrap: wrap;
  }
  .pew-5{
    padding-right: 0;
  }
  .pdw-1{
    padding: 1rem 0;
  }
  .pde-3{
    padding-right: 0rem;
  }
  .mbm-2{
    margin-bottom: 0.5rem;
  }
  .mbm-3{
    margin-bottom:1rem;
  }
  .chat-container{
    display: flex;
    justify-content: center;
  }
  .campaign-select{
    width:100%;
  }
  .auth-side{
    display: none;
  }
  .mb-pd{
    margin-left: 2rem;
  }
  .profilePhoto{
    width:10rem;
  }
  .mrx-5{
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .wt-75{
    padding:1rem;
    width:100%;
  }
  .w-230{
    width:60%;
  }
  .sideNav.active{
    display: block;
  }
  .sideNav{
    display: none;
    position:fixed;
    z-index: 100;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
  }
  .hide-toggler,.show-toggler{
    display:block;
}
  .show-toggler{
    position: fixed;
    z-index: 1;
    bottom: 3rem;
    left: 2rem;
    background: #c2e7ff;
  }
.pdx-6,.pd-6{
  padding-right: 1rem;
  padding-left: 1rem;
}
.grid{
  grid-template-columns: 1fr;
}
}
@media only screen and (max-width : 700px) {
  .campains-select{
    width:100%
  }
  .wt-50{
      width:100%;
  }
}
@media only screen and (max-width: 400px) {
  .chat-container{
    width:100%;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
